<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="mentalCare-4-1">身心照顧</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">心理狀況</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up">ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up">選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up">為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="select-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜心理狀況｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>懷孕特別容易罹患憂鬱症？</h3>
                        <p>懷孕是喜悅的事，但對一個即將成為母親的女人而言，卻也伴隨著許多有形及無形的壓力，如孕期與產後的生理不適、生活習慣的改變、夫妻關係的調適、寶寶的健康、及哺育孩子的重擔等，都牽動著準媽媽的心情。除了期待，其實準媽媽們，更常需要面對緊張、焦慮、憂鬱等情緒。
因此，是否懷孕特別容易造成憂鬱症呢？嘉義長庚紀念醫院精神科醫師蔡景淑表示，其實憂鬱症是一個極為常見的精神疾患，懷孕期間出現憂鬱症的比例並不會高過原本憂鬱症的發生的比例。只要適當的處理各種負面情緒，準媽媽便能順利度過孕期，迎接新的人生階段。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>孕期憂鬱影響胎兒健康</h3>
                        <p>蔡景淑醫師提到，準媽媽們會在懷孕期間出現憂鬱症狀的危險因素有下列幾項：1.青春期懷孕、2.未婚、3.經濟條件差、4.缺少社會支持、5.曾有憂鬱性疾患、6.近期出現負面的生活事件。而孕期憂鬱若未妥善治療，對孕婦及胎兒都將帶來許多影響，除了會導致孕婦本身營養不良、產前照顧差之外，還會出現子癇前症、抽菸、飲酒與其他物質濫用和自殺的風險，另外也會提高流產、早產、胎兒出生體重過輕、胎兒日後出現睡眠問題，及語言和行為發展異常等機率。因此蔡景淑醫師提醒準媽媽的家人及朋友，給有憂鬱症狀的準媽媽多一些關心與支持，協助及早就醫。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>深入認識產後憂鬱</h3>
                        <p>蔡景淑醫師提到，近期大部分的文獻將生產後的情緒障礙分為三大類，以下便依這三類的情緒障礙分別說明症狀、發生原因、治療方式及注意事項，讓產婦及身邊的家人能夠對產後可能面對的情緒障礙有所認識及預備：</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>＊產後情緒低落：</h3>
                        <p>產後情緒低落，屬於最輕微的產後情緒障礙，約15%至85%的產婦在產後十天內會出現低落的情緒，大部分在產後第五天出現，憂鬱的狀態維持時間大多不超過兩週，主要的情緒表現為心情時而低落時而愉悅、躁動、哭泣、疲憊與困惑等，較容易發生在之前即有與懷孕無關的憂鬱病史與經前不悅症的婦女身上。治療上僅需心理支持與疾病衛教即可，所以讓產婦能有情緒上的宣洩與支持在此刻非常重要。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>＊產後重鬱症：</h3>
                        <p>產後重鬱症 （postpartum depression），其整體的盛行率為10%至15%，一般在產後四週內開始出現與重度憂鬱症相似的症狀，包括情緒低落、無法感受到快樂、認知能力下降、睡眠品質差、胃口改變（吃不下或吃太多）、罪惡感與無法給予新生兒適宜的照顧等。相關研究指出，產後重鬱症的好發時期為產後二到六個月之間，最高的機率出現在產後九十天內。依嚴重度的不同，大部分持續時間為三到六個月，少部分的患者會持續一年。
容易出現產後重鬱症的因素有年輕產婦、不預期懷孕、收入不穩定、婚姻衝突、缺乏支持系統、憂鬱症病史、患有經前不悅症或孕期憂鬱／焦慮等，特別值得注意的是，產後重鬱症患者可能會對自己無法克盡母職而自責，而出現自殺或帶嬰兒一起尋死的行為。治療上與一般重鬱症無異，但對於仍哺育母乳的產婦，抗憂鬱劑種類的選擇則須特別謹慎。</p>
                      </li>
                    <li data-aos="fade-up">
                      <p>參考資料：<a class="fs-5 text-danger" href="https://www.mamaway.com.tw/pregnancy-nursing-breastfeeding/6792/pregnancy/ " target="_blank">Mamaway《心情很Blue？擺脫孕期+產後憂鬱》</a></p>
                    </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
